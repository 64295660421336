/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Images
import checkWhite from 'img/check_white.svg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
// import CTABanner from 'components/Common/CTABanner'
import HuisOpgeven from 'components/Diensten/HuisOpgeven'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import DienstenLijst from 'components/Common/DienstenLijst'
import ProtoContent from 'components/Diensten/ProtoContent'

// Third Party
import styled from 'styled-components'

const Dienst = styled.div``

const Header = styled.div`
  background-color: #272d46;
  color: #ffffff;
  padding-bottom: 0;
  margin-bottom: 5rem;
  max-height: 477px;

  @media screen and (max-width: 1199px) {
    max-height: unset;
  }

  & p {
    font-size: 16px;
  }
`

const USPS = styled.div`
  padding: 10px 0;
  color: #ffffff;
  /* opacity: .6; */

  & p {
    margin-bottom: 0;
    font-size: 24px;
  }
`

const Icon = styled.img`
  height: 24px;
  width: 24px;
`

const MainText = styled.div`
  & p {
    font-size: 16px;
  }

  & li {
    font-size: 18px;
  }

  & h2 {
    font-size: 20px;
  }
`

const ImageContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  top: 3rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`

const LandingspageNew = ({ data: { page }, pageContext }) => (
  <Layout>
    {/* {() => ( */}
      <>
        <SEO seo={page.seo} />
        <Dienst>
          <CustomBreadCrumb
            data={page}
            className="py-2"
            pageContext={pageContext}
          />
          <Header className="pt-lg-4">
            <div className="container">
              <div className="row justify-content-left">
                <div className="col-12 d-lg-block d-none pb-5">
                  <USPS className="d-flex align-items-center justify-content-between">
                    {page.landingsPageNew.landingPagina.usps.map((usp) => (
                      <div className="d-flex align-items-center">
                        <Icon className="mr-2" src={checkWhite} />
                        <ProtoContent content={usp.usp} />
                      </div>
                    ))}
                  </USPS>
                </div>
                <div className="col-lg-6">
                  <div className="h-100 mt-4 pt-lg-5 pt-3 d-flex align-items-center">
                    <ProtoContent
                      content={page.landingsPageNew.landingPagina.headerText}
                    />
                  </div>
                </div>
                <div className="col-lg-6 pl-lg-5">
                  <ImageContainer>
                    <Plaatjie
                      image={page.landingsPageNew.landingPagina.headerImage}
                      alt=""
                    />
                  </ImageContainer>
                </div>
              </div>
            </div>
          </Header>
          <div className="container">
            <div className="row py-5">
              <div className="col-12 col-lg-7 pl-lg-3">
                <MainText className="col-12 col-lg-7 pl-lg-3">
                  <ProtoContent
                    content={page.landingsPageNew.landingPagina.description}
                  />
                </MainText>
              </div>
              <div className="col-12 col-lg-5 pt-5 pr-lg-3">
                <HuisOpgeven className="pb-5 pt-4 justify-content-center justify-content-lg-end" />
                <DienstenLijst className="d-flex flex-wrap justify-content-center justify-content-lg-end" />
              </div>
            </div>
          </div>
          {/* <CTABanner data={page} /> */}
        </Dienst>
      </>
    {/* )} */}
  </Layout>
)

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      databaseId
      landingsPageNew {
        landingPagina {
          usps {
            usp
          }
          headerText
          headerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 2000)
              }
            }
          }
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default LandingspageNew
